@import 'styles/forms/full.form.scss';
@import 'styles/fields/datepicker.scss';
@import 'styles/fields/autosuggest.scss';
@import 'styles/pagination.scss';

.app{
  font-size: 14px !important;
  button {
    font-size: 14px !important;
  }

  text-align: center;
}

.table  .td {
  vertical-align: middle;
}

.navbar.bg-light {
  background: #fff !important;
  box-shadow: 1px 1px 50px #dcdcdc;

  .navbar-brand {
    margin-right: 35px;

    img {
      margin-right: 15px;
      max-height: 48px;
    }
  }
}
