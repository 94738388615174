@import '../variables';

.full-form-container {
  h3 {
    margin-top: 1.5rem;
  }

  text-align: left;

  label {
    display: block;
    text-transform: capitalize;
  }

  .btn-remove {
    height: fit-content;
  }

  label {
    position: relative;

    .field-required {
      font-size: 1.5rem;
      font-weight: bold;
      color: $color-error !important;
      position: absolute;
      top: -5px;
    }

  }

}